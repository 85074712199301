import React from 'react'
import {NavLink} from 'react-router-dom'

const SearchContent = () => {
    
  return (
    <>

<div id='ftts'>
    <ul id='myul'>
    <li><NavLink to="/ourschool">Our School</NavLink></li>
        <li><NavLink to="/facilities">Facilities</NavLink></li>
        <li><NavLink to="/academicprocedure">Academic</NavLink></li>
        <li><NavLink to="/principal">Principal</NavLink></li>
        <li><NavLink to="/boarddirector">Board of Director</NavLink></li>
        <li><NavLink to="/contactus">Contact</NavLink></li>

    </ul>
    </div>
    </>
  )
}

export default SearchContent