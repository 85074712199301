import React from 'react'
// import "./css/Gallery.css"

const Gallery = () => {
  return (
    <>
    <div className="container-fluid">
        <div className="first">
            <div className="one">
                <img src="./images/p9.jpg" alt="" />
            </div>
            <div className="one">
                <img src="./images/p2.jpg" alt="" />
            </div><div className="one">
                <img src="./images/p3.jpg" alt="" />
            </div><div className="one">
                <img src="./images/p4.jpg" alt="" />
            </div>
        </div>
        <div className="first">
            <div className="one">
                <img src="./images/p5.jpg" alt="" />
            </div>
            <div className="one">
                <img src="./images/p11.jpg" alt="" />
            </div><div className="one">
                <img src="./images/p7.jpg" alt="" />
            </div><div className="one">
                <img src="./images/WhatsApp Image 2022-11-17 at 2.29.10 PM (1) (1).jpeg" alt="" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Gallery