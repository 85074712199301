import React from "react";
// import "./css/BannerS.css";
// import "./css/LatestNews.css";
// import "./css/Admission.css";
// import "./css/HlastSection.css";
import { NavLink } from "react-router-dom";

const BannerSection = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <img
                className="d-block slideimage"
                src="./images/mahi-slider.jpeg"
                alt="First slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h1>
                  <b>Mahi Academy</b>
                </h1>
                <p>
                  <b>
                    We at Mahi Academy provide a balanced educational Experience
                  </b>
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                className="d-block slideimage"
                src="./images/WhatsApp Image 2022-11-17 at 3.53.55 PM (3) (1).jpeg"
                alt="Second slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h1 style={{ color: "black" }}>
                  <b>Dynamic Educators</b>
                </h1>
                <p>
                  <b style={{ color: "#262624" }}>
                    Mahi Academy to facilitate 21st century teaching and
                    learning for its teachers and students.
                  </b>
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                className="d-block slideimage"
                src="./images/WhatsApp Image 2022-11-17 at 3.53.55 PM (1) (1).jpeg"
                alt="Third slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h1>
                  <b>Hands-on learning</b>
                </h1>
                <p>
                  <b>
                    Since its inception, Mahi Academy has consistently grown
                    year after year and has gained a reputation of becoming a
                    leading educational institute.
                  </b>
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                className="d-block slideimage"
                src="./images/WhatsApp Image 2022-11-17 at 3.53.55 PM (2) (1).jpeg"
                alt="Fourt slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h1>
                  <b>Safe and Caring Environment</b>
                </h1>
                <p>
                  <b>
                    Feeling safe and cared for is what makes our school
                    community a complete family!
                  </b>
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                className="d-block slideimage"
                src="./images/WhatsApp Image 2022-11-17 at 4.03.49 PM.jpeg"
                alt="Fifth slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h1>
                  <b>21st Century Education</b>
                </h1>
                <p>
                  <b>
                    Embracing state-of-the-art technology in our classrooms
                    allows Mahi Academy to facilitate 21st century teaching and
                    learning for its teachers and students.
                  </b>
                </p>
              </div> */}
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon jj"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon jj"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </a>
        </div>
      </div>

      {/* Latest New Section---------------------- */}

      <div className="container-fluid p-0">
        <h1 className="heading1 ada">Facilities</h1>

        <div className="container-fluid px-5 d-flex latest_card_box">
          <div className="lnewsleft p-3">
            <div className="card_img1">
              <img src="./images/smart.jpg" alt="" />
            </div>
            <div className="title">
              <h3 className="my-3 ak">Smart Classes</h3>
              <p>
                The learning experience can be enhanced by educational
                technology for students of all ages and can even assist in the
                education of students with their special needs and wants.
              </p>
            </div>
          </div>
          <div className="lnewsmid p-3">
            <div className="card_img1">
              <img src="./images/bus2.jpeg" alt="" />
            </div>
            <div className="title">
              <h3 className="my-3 ak">Transportation</h3>
              <p>
                The school provides transport facility to the students through
                Buses & Magic for transporting the students from different
                localities to the school and vice-versa.
              </p>
            </div>
          </div>
          <div className="lnewsright p-3">
            <div className="card_img1">
              <img src="./images/scholar.jpg" alt="" />
            </div>
            <div className="title">
              <h3 className="my-3 ak">Scholarship</h3>
              <p>
                Whether you're starting your career or you're studying in high
                school to grow new abilities, receiving a scholarship is a big
                achievement.
              </p>
            </div>
          </div>
        </div>

        <div className="latestbutton p-2">
          <NavLink className="btn btn-lg kop viewbtn" to="/facilities">
            View all New
          </NavLink>
        </div>
      </div>

      {/* Admission Section ---------- */}

      <div className="container-fluid mt-3 p-0">
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="ladmleft p-5 text-center">
            <div className="title">
              <h1 className="my-3 lnns">
                Our <span style={{ color: "yellow" }}>School</span>
              </h1>
              <p>
                Mahi Academy provides an learning environment with a commitment
                of using modern day teaching pedagogy to cultivate an advanced
                and comprehensive academic program; fully compliant with the
                Mahi Academy curriculum of studies. We are a Nursery to 8th
                school with a wide array of resources designed to foster student
                achievement that will prepare our pupils to pursue higher
                education and be competitive in their careers.
              </p>
            </div>
            <div className="readbutton p-2">
              <NavLink className="btn btn-lg bg-warning adm" to="/ourschool">
                Read More
              </NavLink>
            </div>
          </div>

          <div className="ladmright p-5 text-center">
            <div className="title">
              <h1 className="my-3 lnns">
                <span style={{ color: "yellow" }}>Mahi Academy </span>
                Admission
              </h1>
              <p className="mt-3">
                The Mahi Academy gives a fair and equal chance to all applicants
                who fulfill the admission requirements; dependent upon space
                availability. To determine admission eligibility for students
                transferring from another school, official report cards as well
                as other supporting documents are submitted upon application.
              </p>
            </div>
            <div className="readbutton p-2">
              <NavLink
                className="btn btn-lg bg-warning adm mt-3"
                to="/academicprocedure"
              >
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/* Home LAst Section ------------ */}

      <div className="container-fluid p-0">
        <div className="container-fluid p-0  d-flex latest_card_box_hls">
          <div className="hlsleft">
            <div className="ccc1_img">
              <img
                src="./images/WhatsApp Image 2022-11-17 at 2.29.09 PM.jpeg"
                alt=""
              />
            </div>
            <div className="ccc2_img">
              <img
                src="./images/WhatsApp Image 2022-11-17 at 2.28.57 PM (1).jpeg"
                alt=""
              />
            </div>
          </div>
          <div className="hlsmid text-center p-5">
            <div className="card_imgl my-5">
              <img src="./images/mlogo1.png" alt="" />
            </div>
            <div className="title1 my-5">
              <div className="hlstbutton1">
                <NavLink
                  className="btn btn-lg kop kophl text-nowrap"
                  to="/gallery"
                  style={{ backgroundColor: "#2B5642", color: "white" }}
                >
                  Student Life
                </NavLink>

                <NavLink
                  className="btn btn-lg kop  kophl text-nowrap"
                  to="/academicprocedure"
                  style={{ backgroundColor: "#143325", color: "white" }}
                >
                  Admission
                </NavLink>
              </div>
            </div>

            <p>
              At the Mahi Academy, we believe in giving our students a voice and
              instilling in them a passion to be involved with their school
              community. It is evident that students are at the forefront of
              understanding the needs of making their school into an environment
              that promotes a safe and healthy learning environment. For Mahi
              Academy reason, Mahi Academy is committed in providing a well
              balanced spiritual, academic and social well-being experience for
              it’s students. Students are encouraged to hone their talents in
              order to serve and participate in and beyond our community.
            </p>
            <div className="title1">
              <div className="hlstbutton">
                <NavLink
                  className="btn btn-lg hls px-4 py-3"
                  to="/facilities"
                  style={{ backgroundColor: "#2B5642", color: "white" }}
                >
                  Learn More
                </NavLink>
              </div>
            </div>
          </div>
          <div className="hlsright">
            <div className="ccc2_img">
              <img
                src="./images/WhatsApp Image 2022-11-17 at 2.29.06 PM.jpeg"
                alt=""
              />
            </div>
            <div className="ccc1_img">
              <img
                src="./images/WhatsApp Image 2022-11-17 at 2.29.00 PM (1).jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSection;
