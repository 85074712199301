import React from 'react'
// import './css/Navbar.css'
import SearchContent from './SearchContent'


const Navbar = () => {

  const searchfac = ()=>{

    var filter = document.getElementById("ser").value.toUpperCase()
    let fbtts = document.getElementById("ftts")

    let ul =document.getElementById("myul");
    ul.style.display ="block"
    if(filter===''){
      ul.style.height = "100px"
      fbtts.style.height = "0px"
      ul.style.overflow = "scroll"

  }
  else{
    ul.style.height = "initial"
      fbtts.style.height = "initial"
  }

    let li = ul.getElementsByTagName('li');
    
    for(var i=0; i<li.length; i++){
    let a  = li[i].getElementsByTagName('a')[0]
    let textvalue =a.textContent || a.innerHTML
    
    if(textvalue.toUpperCase().indexOf(filter) > - 1){
     li[i].style.display ='';
    }else{
        li[i].style.display ='none';
    }
    
    }
    
    }


   
  return (
    <>
    <div className="container-fluid navmain">
       <div className="navleft">
         <div className="logo">
            <img src="./images/mlogo1.png" alt="" />
         </div>
       </div>
       <div className="navmid">
           <h1><span id='firsth'>M</span>AHI <span id="firsth">A</span>CADEMY</h1>
           <p>Learning today. Leading tomorrow. Inspired Forever.</p>
       </div>
       <div className="navright">
            <div className="socialmedia">
            <a href="https://www.facebook.com/profile.php?id=100057102863731" target="_blank"style={{textDecoration:"none",color:"white"}}> <i className="ri-facebook-fill"></i></a>

            <i className="ri-linkedin-box-fill"></i>
            <a href="https://www.instagram.com/mahiacademyofficial/?hl=en" target="_blank"style={{textDecoration:"none",color:"white"}}><i class="ri-instagram-line"></i></a>
            {/* <i className="ri-youtube-line"></i> */}
            </div>
            <div className="seacrch">
                <input id='ser' className='aa' type="search" onKeyUp={searchfac}/>
                <div className="btn btn-warning">Search</div>
            </div>
           

       </div>





    </div>
    
    <SearchContent/>
    
    
    
    </>
  )
}

export default Navbar