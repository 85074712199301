import React from 'react'
import {NavLink} from "react-router-dom"
// import './css/Footer.css'
import SearchContent from './SearchContent'

const Footer = () => {


  const searchfacft = ()=>{
    let fbts = document.getElementById("ftbs")
    var filterf = document.getElementById("fser").value.toUpperCase()
    let ful =document.getElementById("myfooterul");
    ful.style.display ="block"

    if(filterf===''){
        ful.style.height = "100px"
        fbts.style.height = "0px"
        ful.style.overflow = "scroll"

    }
    else{
      ful.style.height = "initial"
        fbts.style.height = "initial"
    }
    let li = ful.getElementsByTagName('li');
    
    for(var i=0; i<li.length; i++){
    let a  = li[i].getElementsByTagName('a')[0]
    let textvalue =a.textContent || a.innerHTML
    
    if(textvalue.toUpperCase().indexOf(filterf) > - 1){
     li[i].style.display ='';
    }else{
        li[i].style.display ='none';
    }
    
    }
    
    }


    
   
  return (
    <>
    <div className="container-fluid footermain p-2">
       
       <div className="footermid">
           <div className="footerbottm">
             <div className="fbleft">
                <li className='my-3'><NavLink  to="/">Home</NavLink></li>
                <li className='my-3'><NavLink  to="/boarddirector">Board of Director</NavLink></li>
                <li className='my-3'><NavLink  to="/principal">Principal</NavLink></li>
                <li className='my-3'><NavLink  to="/gallery">Gallery</NavLink></li>
             </div>
             <div className="fbright">
                <li className='my-3'><NavLink  to="/ourschool">About Us</NavLink></li>
                <li className='my-3'><NavLink  to="/academicprocedure">Academic</NavLink></li>
                <li className='my-3'><NavLink  to="/facilities">Facilities</NavLink></li>
                <li className='my-3'><NavLink  to="/contactus">Contact Us</NavLink></li>
             </div>
           </div>

           
       </div>

       <div className="footerleft">
         <div className="logo">
            <img src="./images/mlogo1.png" alt="" />
         </div>
       </div>
       <div className="fbrightt">
            <div className="socialmedia1">
           <a href="https://www.facebook.com/profile.php?id=100057102863731" target="_blank"style={{textDecoration:"none",color:"white"}}> <i className="ri-facebook-fill"></i></a>
            <i className="ri-youtube-line"></i>
           <a href="https://www.instagram.com/mahiacademyofficial/?hl=en" target="_blank"style={{textDecoration:"none",color:"white"}}><i class="ri-instagram-line"></i></a>

            </div>
            <div className="seacrch">
                <input id='fser' className='aa' type="search" onKeyUp={searchfacft } />
                <div className="btn btn-lg btn-warning" >
                <i className="ri-search-line"></i>
                </div>
            </div>
           <div id='ftbs'>
            <ul id='myfooterul'>
            <li><NavLink to="/ourschool">Our School</NavLink></li>
        <li><NavLink to="/facilities">Facilities</NavLink></li>
        <li><NavLink to="/academicprocedure">Academic</NavLink></li>
        <li><NavLink to="/principal">Principal</NavLink></li>
        <li><NavLink to="/boarddirector">Board of Director</NavLink></li>
        <li><NavLink to="/contactus">Contact</NavLink></li>

    </ul>
  
    </div>


            <div className="copyright mt-2">
              <p id='copyr'>© Made with &nbsp;<i className="ri-heart-fill" style={{color:"red", fontSize:"20px"}}> </i> <a style={{color:"white", textDecoration:"none"}} target="_blank" href='https://maitretech.com/'>&nbsp; by Maitretech Solution</a></p>
            </div>
       </div>





    </div>
    
    
    
    <div className="fbtm">
            <p>A-4,5  Suryasukham Colony Near Signature Heights , Shalimar Palm, Road ,Pipliyahana Indore ,- 452001 (M.P.) 

         </p>
           </div>
    
    
    </>
  )
}

export default Footer