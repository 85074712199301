
import Home from './Components/Home';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import NextNavbar from './Components/NextNavbar';
import "./App.css"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from './Components/Gallery';
import Facilities from './Components/Facilities';
import ContactUs from './Components/ContactUs';
import AcademicPRocedure from './Components/AcademicPRocedure';
import OurSchool from './Components/OurSchool';
import BoardD from './Components/BoardD';
import Principal from './Components/Principal';
import ScrollToTop from './Components/ScrollToTop';


function App() {
  const removeSrft = ()=>{
    let fbts = document.getElementById("ftbs")
    let fbtts = document.getElementById("ftts")

    let ful =document.getElementById("myfooterul");
    let ul =document.getElementById("myul");

      ul.style.display ="none"
      ful.style.height="initial"
      ul.style.height="initial"

      ful.style.display ="none"
        fbts.style.height = "initial"
        fbtts.style.height = "initial"


    }
  return (
    <>
    <div className='container-fluid mainbgcch p-0' onClick={removeSrft}>
      <BrowserRouter>
      <ScrollToTop/>
      <Navbar/>
      <NextNavbar/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={ <Gallery/>} />
          <Route path="/facilities" element={ <Facilities/>} />
          <Route path="/contactus" element={ <ContactUs/>} />
          <Route path="/academicprocedure" element={ <AcademicPRocedure/>} />
          <Route path="/ourschool" element={ <OurSchool/>} />
          <Route path="/boarddirector" element={ <BoardD/>} />
          <Route path="/principal" element={ <Principal/>} />


      </Routes>
      <Footer/>
    </BrowserRouter>


    </div>
    
    
    
    </>
  );
}

export default App;
