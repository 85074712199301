import React from "react";
import BannerSection from "./BannerSection";
// import "./css/Facility.css";

const Facilities = () => {
  return (
    <>
      <div className="container-fluid p-0">
        {/* <BannerSection /> */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <img
                className="d-block slideimage"
                src="./images/WhatsApp Image 2022-11-17 at 3.53.55 PM (4).jpeg"
                alt="First slide"
              />
              
            </div>
          </div>
        </div>
        <div className="container liscontainer">
          <div hidden className="liscamplogo my-3">
            <img src="./images/WhatsApp Image 2022-11-17 at 2.28.56 PM.jpeg" alt="" />
          </div>
          <div className="facilitycontent">


            {/* smart class */}
            
            <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/smart.jpg" alt="" /> 
              </div>
              <div className="facontent">
                <div className="factitle">Smart Classes</div>
                <p>The learning experience can be enhanced by educational technology for students of all ages and can even assist in the education of students with their special needs and wants. These high-tech tools contribute an important role for students in education. In fact, technology is an entryway to education and professional skills.</p>
              </div>
            </div>

{/* transport facility */}

<div className="facilitCard">
              <div className="facilimages">
                <img src="./images/bus1.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Transport Facility</div>
                <p>The school provides transport facility to the students through Buses & Magic for transporting the students from different localities to the school and vice-versa. Students can avail the Transport facility by putting up an application. All the buses have trained driver’s and conductor’s. The school follows all the rules and regulations of M.P. Government for the buses.</p>
              </div>
            </div>





      {/* scholarship*/}

<div className="facilitCard">
              <div className="facilimages">
                <img src="./images/scholar.jpg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Scholarship</div>
                <p>The school provides Whether you're starting your career or you're studying in high school to grow new abilities, receiving a scholarship is a big achievement.</p>
              </div>
            </div>



            {/* skilled */}

            {/* <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/slide3.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Skilled Staff</div>
                <p>Skilled Staff is here to teach or guide for your bright carrier and and provides Handson in our School.Teachers play a key role in identifying and supporting students who are at risk of dropping out, especially students from vulnerable populations, such as girls ...</p>
              </div>
            </div> */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/p17.jpg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Events Organisation</div>
                <p>Mahi Academy oraganise an Event on all Festivals like Rangoli Competition , Holi, Krishna Janmastmi , Republic Day , Annual Function and also organise Competition  and provide Rewards </p>
              </div>
            </div>


              {/* sports */}
            <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/p11.jpg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Sports</div>
                <p> Indoor games and sports are a variety of structured games or competitive physical exercises, Extensive and well maintained play fields of  Indoor facilities of chess, caroms, table tennis etc. are also available.</p>
              </div>
            </div>




              {/* nice Infrastructure */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/p13.jpg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Nice Infrastructure</div>
                <p>The fact is that a good school infrastructure, with renewed spaces, makes it possible for children and youths that live in remote areas to study .A good school infrastructure makes it a good place for the children to study, which is important as the student spends maximum time in school.</p>
              </div>
            </div>

        {/* Good Academic */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/p14.jpg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Good Academic</div>
                <p>We provide our student with good encyclopedia to reduce their encumbrance regarding any subject academically or globally. We teach students by smart classes for active participation and deep knowledge.</p>
              </div>
            </div>

          



             {/* hygiene washroom */}
             <div className="facilitCard">
              <div className="facilimages">
                <img src="./images/WhatsApp Image 2022-11-17 at 2.29.10 PM (1) (1).jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Plant Transplantation</div>
                <p>Transplantation is the technique in which a plant is moved from one location to other. It involves growing of seedlings or seeds in a piece of well managed and protected land called nursery. After development of a few weeks the plants are transplanted into main field for further growth and development.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
